export const trackSuccessLoginEvent = (userId: number): void => {
  window.dataLayerVerbit.push({
    event: "login_success",
    user_id: userId,
    method: "email",
  });
};

export const trackFailLoginEvent = (failReason: string): void => {
  window.dataLayerVerbit.push({
    event: "login_fail",
    fail_reason: failReason || "Unknown error",
    method: "email",
  });
};