import { useState, createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../UserPool";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { baseUrl, loginUrl } from "../../utils/env";
import { analyticsService } from "../../services/AnalyticsService";
import { trackSuccessLoginEvent } from "../../services/GoogleAnalytics";
import { IAccountContext, ValuesType } from "../types";
import { useAccountHelpers } from "../../hooks/useAccountHelpers";

const defaultValues: ValuesType = {
  email: '',
  password: '',
  fullNumber: '',
  errorMsg: '',
  disabled: false,
  redirectUrl: null,
  loading: false,
  codeSentTwice: false,
  cognitoUser: null,
  academyUrl: '',
  phoneNumberVerified: false,
  confirmPassword: '',
  showPassword: false,
  showConfirmPassword: false,
  confirmationCode: null,
  congitoUser: null,
  idToken: null,
  accessToken: null,
  username: null,
};

const defaultContextValue: IAccountContext = {
  updatePhoneNumber: async () => { /* Default implementation */ },
  setValues: () => { /* Default implementation */ },
  getSession: async () => { /* Default implementation */ },
  authenticate: async () => ({ academyUrl: '' }), // Mocked return type
  redirectUser: async () => { /* Default implementation */ },
  redirectInProgress: false,
  code: '',
  setCode: () => { /* Default implementation */ },
  confirmSignUp: async () => { /* Default implementation */ },
  userPhone: null,
  errMsg: null,
  cognitoUserObj: null,
  values: defaultValues,
};

const AccountContext = createContext<IAccountContext>(defaultContextValue);

interface AuthenticationResponse {
  academyUrl: string;
}

declare global {
  interface Window {
    FB: any;
    dataLayerVerbit: any[];
  }
}

const processingDurations: { [key: string]: any } = {};

// This function is used to set the start and end time of a specific topic in the processingDurations object.
const setProcessingDurations = (topic: any, startTime: any, endTime: any) => {
  if (!processingDurations[topic]) {
    processingDurations[topic] = {};
  }
  if (startTime) {
    processingDurations[topic].startTime = startTime;
  }
  if (endTime) {
    processingDurations[topic].endTime = endTime;
  }
  return processingDurations;
};

// Functions from Account.js are used in Login.js and Verification.js

const Account = (props: any) => {
  const [values, setValues] = useState<ValuesType>({
    password: "",
    confirmPassword: "",
    email: "",
    showPassword: false,
    showConfirmPassword: false,
    errorMsg: undefined,
    loading: false,
    disabled: false,
    confirmationCode: null,
    congitoUser: null,
    redirectUrl: null,
    idToken: null,
    accessToken: null,
    codeSentTwice: false,
    username: "",
  });

  const { removeHubspotCookie } = useAccountHelpers();

  const [cognitoUserObj, setCognitoUserObj] = useState<CognitoUser | null>(null);
  const [userPhone, setUserPhone] = useState(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [code, setCode] = useState("");
  const [redirectInProgress, setRedirectInProgress] = useState<boolean>(false);

  let navigate = useNavigate();

  // Get the current session from Cognito
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err: any, session: any) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };

  const windowRedirectUrl = (url: any) => {
    window.location.href = url;
  };

  const sessionConfig = (idToken: any, phone_collected: any) => {
    return {
      method: "post" as const,
      url: `${loginUrl}/api/v2/user/session`,
      withCredentials: true,
      headers: { "X-Requested-With": "XMLHttpRequest" },
      data: {
        cognito_jwt_token: idToken,
        phone_collected,
      },
    };
  };

  // A redirect function that checks if the user's phone number is already collected (phone_collected). If not, it will navigate the user to "/phone" route or redirects to "redirectUrl" or "academyUrl" or the "baseUrl".
  const redirectUser = async (idToken: any, phone_collected: any) => {
    await axios(sessionConfig(idToken, phone_collected))
      .then((response) => {
        if (response?.data?.collect_phone_number) {
          navigate("/phone");
        } else {
          setRedirectInProgress(true);
          if (values.redirectUrl) {
            const decoded_url = decodeURI(values.redirectUrl);
            windowRedirectUrl(decoded_url);
          } else if (!values.redirectUrl && !(values as any).academyUrl) {
            windowRedirectUrl(baseUrl);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 431) {
          console.error("Error 431: Request Header Fields Too Large");
          setValues({ ...values, loading: false, errorMsg: "There was a problem logging in. Please clear your cookies and try again." });
        } else {
          // Handle other errors
          console.error(
            "Something went wrong and we couldn't log you in. Please check your connection and try again. If the problem persists, reach out to our support team."
          );
        }
      });
  };

  // A function that sends a confirmation code to the user's phone number in case of collecting a new phone number or updating an existing one.
  const confirmSignUp = async (cognitoUser: any, attribute: any, code: any) => {
    setValues({ ...values, loading: true });
    try {
      await Auth.verifyUserAttributeSubmit(cognitoUser, attribute, code);
      analyticsService.track("Login - Updated phone succesfuly confirmed", { User: values.email }, redirectUser(values.idToken, true));
    } catch (error) {
      setCode("");
      setValues({ ...values, loading: false, disabled: false, errorMsg: "The code entered does not match what we sent, or has expired." });
      analyticsService.track("Login - Updated phone failed to confirm", { User: values.email }, () => {});
      (window as any).Rollbar?.error("Login - Updated phone failed to confirm", { User: values.email }, error);
    }
  };

  const updatePhoneNumber = async (Username: any, Password: any, phoneInput: any, token: any) => {
    const user = new CognitoUser({ Username, Pool });
    setValues({ ...values, username: Username });
    setValues({ ...values, cognitoUser: user, disabled: true });

    const attributeList = [{ Name: "phone_number", Value: phoneInput }];
    return new Promise((resolve, reject) => {
      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      // Check if phone already exists
      axios({
        method: "post",
        url: `${loginUrl}/api/v1/user/verify-phone`,
        headers: { Authorization: `Bearer ${token}` },
        data: { phoneNumber: `${phoneInput}` },
      })
        .then((response) => {
          user.authenticateUser(authDetails, {
            onSuccess: (data) => {
              setValues({ ...values, idToken: (data as any).idToken.jwtToken });
              if (phoneInput === userPhone) {
                //
                // Note::
                // If phone already set, updateAttributes won't send sms for verification so we need to send it directly.
                //
                const sendCode = async () => {
                  await Auth.verifyUserAttribute(cognitoUserObj, "phone_number")
                    .then((data) => {
                      analyticsService.track("Login - first code was sent.", { User: values.email }, navigate("/verification"));
                    })
                    .catch((err) => {
                      analyticsService.track("Login - failed to send first code.", { User: values.email }, () => {});
                    });
                };
                sendCode();
              } else {
                //
                // Note::
                // updateAttributes does both update the cognito phone attribute, and trigger sending of sms for verification.
                //
                analyticsService.track("Login - User updated existing phone number to new number", { User: values.email }, () => {});
                user.updateAttributes(attributeList, function (err, result) {
                  if (err) {
                    setValues({
                      ...values,
                      loading: false,
                      errorMsg: "There was a problem updating your phone number. Try again later. If the problem persists, contact customer support.",
                    });
                    analyticsService.track("Login - Failed updating phone number", { User: values.email }, () => {});
                    (window as any).Rollbar?.error("Login - Failed updating phone number", { User: values.email }, err);
                    return;
                  }
                  if (result === "SUCCESS") {
                    analyticsService.track("Login - Updated phone number succesfuly", { User: values.email }, navigate("/verification"));
                  }
                });
              }
            },
            onFailure: (err) => {
              (window as any).Rollbar?.error("Login - Failed to authenticate user", { User: values.email }, err);
              if (err.message === "reAuthentication failed with error Unconfirmed Self service user.") {
                setValues({
                  ...values,
                  loading: false,
                  disabled: false,
                  errorMsg: "",
                });
              } else {
                setValues({
                  ...values,
                  loading: false,
                  disabled: false,
                  errorMsg: "There was a problem logging in. Try again later. If the problem persists, contact customer support.",
                });
              }
            },
            mfaRequired: () => {},
          });
        })
        .catch((err) => {
          let errorMessage = "This phone number can not be used. Please try again.";
          if (err.response?.status === 429) {
            errorMessage = "Too many attempts, please try again later.";
          }
          setValues({ ...values, loading: false, disabled: false, errorMsg: errorMessage });
          setErrMsg("Please contact support");
          (window as any).Rollbar?.error(err);
        });
    });
  };

  // A function that authenticates the user with Cognito and sends an event to Mixpanel with the user's email and whether their phone number is verified
  const authenticate = async (Username: string, Password: string): Promise<AuthenticationResponse> => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      setValues({ ...values, username: Username });
      if (user) {
        setValues({ ...values, cognitoUser: user });
        setCognitoUserObj(user);
      }

      const setAuthFlowType = () => {
        try {
          user.setAuthenticationFlowType("USER_PASSWORD_AUTH");
        } catch (err) {
          window.location.href = loginUrl!;
        }
      };
      setAuthFlowType();

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      // Authenticate the user with Cognito
      user.authenticateUser(authDetails, {
        onSuccess: async (data) => {
          // Get idTokenfrom verbit API
          const idToken = (data as any).idToken.getJwtToken();
          // Decode the idToken to extract phone_number and phone_number_verified claims
          const decodedToken = jwt_decode(idToken);
          const platformData = JSON.parse((decodedToken as any)["custom:platform_data"]);
          const accessToken = (data as any).accessToken.getJwtToken();
          // Update state object with the idToken, accessToken, and phoneNumberVerified flag
          setValues({ ...values, idToken: idToken, accessToken: accessToken, phoneNumberVerified: false });
          if (!platformData.self_service) {
            removeHubspotCookie();
          }
          // If the decoded token contains a phone_number claim, update the user's phone number in state and set the phoneNumberVerified flag if the phone number is verified
          if ((decodedToken as any)?.phone_number) {
            setUserPhone((decodedToken as any)?.phone_number);
            if ((decodedToken as any)?.phone_number_verified) {
              setValues({ ...values, phoneNumberVerified: true });
            }
          }
          // Send an event to Mixpanel with the user's email and whether their phone number is verified
          analyticsService.track(
            "Succesful Cognito login",
            {
              User: values.email,
              PhoneVerified: (decodedToken as any)?.phone_number_verified,
            },
            () => {}
          );
          trackSuccessLoginEvent(
            JSON.parse(data.idToken.payload["custom:platform_data"]).id
          )

          // Check if the idToken is larger than 3900 characters, send an error to rollbar
          if (idToken.length > 3900) {
            analyticsService.track("Login - Token is too large", { User: values.email, Token: idToken }, () => {});
            (window as any).Rollbar?.error("Login - Token is too large", { User: values.email, Token: idToken });
          }
          // Check if token 'waf-domain' and redirect if true
          if ((decodedToken as any)?.waf_domain) {
            setValues({ ...values, redirectUrl: (decodedToken as any)?.waf_domain });
            localStorage.setItem("waf-domain", (decodedToken as any)?.waf_domain);
            setRedirectInProgress(true);
            analyticsService.track("Login - User is redirected due to WAF restrictions", { User: values.email }, () => {});
            window.location.href = `https://${(decodedToken as any)?.waf_domain}?redirected=true`;
          } else {
            redirectUser(idToken, null);
          }
        },
        onFailure: (err) => {
          // a list of errors for which logging should be at 'info' level
          const infoErrors = [
            "NotAuthorizedException: Incorrect username or password.", // Cognito error for incorrect credentials
          ];

          if (infoErrors.some((infoErr) => err?.message?.includes(infoErr))) {
            (window as any).Rollbar?.info(`Login - Cognito Info: ${err}`, Username, err);
            analyticsService.track("Cognito login issue", { User: Username, error: err }, () => {});
          } else {
            (window as any).Rollbar?.error(`Login - Cognito Error: ${err}`, Username, err);
            analyticsService.track("Failed Cognito login", { User: Username, error: err }, () => {});
          }

          if (err?.message === "NotAuthorizedException: Incorrect username or password.") {
            setValues({ ...values, loading: false, errorMsg: "Incorrect username or password." });
          } else {
            setValues({
              ...values,
              loading: false,
              errorMsg: "There was a problem logging in. Try again later. If the problem persists, contact customer support.",
            });
          }

          reject(err);
        },
        mfaRequired: (codeDeliveryDetails) => {
          analyticsService.track("Login - MFA initiated.", { User: values.email }, navigate("/confirmphone"));
        },
      });
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        values,
        setValues,
        cognitoUserObj,
        updatePhoneNumber,
        confirmSignUp,
        userPhone,
        errMsg,
        code,
        setCode,
        redirectUser,
        redirectInProgress,
      }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext, setProcessingDurations };
